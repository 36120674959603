interface Modal {
  hide: () => void;
  name: string;
  show: () => void;
}

interface SModal {
  findSubscriber: (name: string) => Modal | undefined;
  hide: (name: string) => void;
  modals: Modal[];
  show: (name: string) => void;
  subscribe: (ref: Modal) => void;
  unsubscribe: (ref: Modal) => void;
}

function sModal(vue: { prototype: { $sModal: SModal } }) {
  vue.prototype.$sModal = {
    modals: [] as Modal[],
    show(name: string) {
      const modal = this.findSubscriber(name);
      if (!modal) {
        return;
      }
      modal.show();
    },
    hide(name: string) {
      const modal = this.findSubscriber(name);
      if (!modal) {
        return;
      }
      modal.hide();
    },
    findSubscriber(name: string) {
      return this.modals.find((modal: Modal) => modal.name === name);
    },
    subscribe(ref: Modal) {
      this.modals.push(ref);
    },
    unsubscribe(ref: Modal) {
      const index = this.modals.findIndex(
        (modal: Modal) => modal.name === ref.name,
      );
      if (index === -1) {
        console.warn(`[SModal] ${ref.name} is not subscribed`);
        return;
      }
      this.modals.splice(index, 1);
    },
  } as SModal;
}

export default sModal;
